providerData<template>
  <v-main>
    <v-container align-left>
      <h3 class="mb-5 mt-5">Trainee Detail</h3>
      <a @click="$router.go(-1)" class="text-left">Back to List</a>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div
        style="position:absolute;margin:0 auto;left:50%,right:50%;"
        v-if="loading"
      >
        Loading...
      </div>
      <v-row>
        <v-col>
          <v-card class="mx-auto" max-width="400" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>{{ userData.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>State/Region</v-list-item-title>
                <v-list-item-subtitle>{{
                  userData.state_region
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Register Date</v-list-item-title>
                <v-list-item-subtitle>{{
                  userData.createddate
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto" max-width="400" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Provider Type</v-list-item-title>
                <v-list-item-subtitle>{{
                  userData.provider_type
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Organization</v-list-item-title>
                <v-list-item-subtitle>{{
                  userData.organization
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <div class="pa-md-5"></div>


      <div v-if="providerLectureProgress">
        <hr/>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Lecture</th>
                <th class="text-left">Progress</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in providerLectureProgress" :key="item.id">
                <td>{{ item.lectureTitle }}</td>
                <td>
                  <v-progress-circular
                    :rotate="180"
                    :size="100"
                    :width="15"
                    :value="item.progress"
                    color="pink"
                  >
                    {{ item.progress }}
                  </v-progress-circular>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <h3 class="mb-5 mt-5">Pre Test</h3>
      <div v-if="traineePretestResult">
        <v-alert
          border="left"
          colored-border
          color="deep-purple accent-4"
          elevation="2"
        >
          <v-row bg-dark>
            <v-col>Attempt Date: {{ traineePretestResult.createddate }} </v-col>
            <v-col>Score: {{ traineePretestResult.score }} </v-col>
            <v-col>Result: {{ traineePretestResult.status }}</v-col>
          </v-row>
        </v-alert>
        <p>
          <br />Score : <br />Result :
          {{ traineePretestResult.status }}
        </p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Pretest Question</th>
                <th class="text-left">Trainee Answer</th>
                <th class="text-left">Mark</th>
                <th class="text-left">Answer Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in traineePretestResult['result']" :key="item.id">
                <td class="text-left">{{ item.question_id }}</td>
                <td class="text-left">{{ item.answer }}</td>
                <td class="text-left">{{ item.mark }}</td>
                <td class="text-left">{{ item.createddate }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-else>haven't started the pre exam yet</div>

      <h3 class="mb-5 mt-5">Post Test</h3>
      <div v-if="traineePosttestResult">
        <div v-for="item in traineePosttestResult" :key="item.id">
          <v-alert
            border="left"
            colored-border
            color="deep-purple accent-4"
            elevation="2"
          >
            <v-row bg-dark>
              <v-col>Attempt Date: {{ item.createddate }} </v-col>
              <v-col>Score: {{ item.score }} </v-col>
              <v-col>Result: {{ item.status }}</v-col>
            </v-row>
          </v-alert>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Post Test Question</th>
                  <th class="text-left">Trainee Answer</th>
                  <th class="text-left">Mark</th>
                  <th>Answer Time</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="itemDetail in item.result" :key="itemDetail.id">
                  <td class="text-left">{{ itemDetail.question_id }}</td>
                  <td class="text-left">{{ itemDetail.answer }}</td>
                  <td class="text-left">{{ itemDetail.mark }}</td>
                  <td class="text-left">{{ itemDetail.createddate }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <div v-else>haven't started the post exam yet</div>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { FETCH_TRAINEE_DETAIL } from "../store/actions.type";
export default {
  name: "TraineeDetail",
  data() {
    return {
      userData: {},
      providerData: {},
      traineePretestResult: null,
      traineePosttestResult: null,
      providerLectureProgress: [],
      providerProgress: [],
      loading: true,
      errored: false,
    };
  },
  computed: {},
  mounted() {
    this.currentUserID = localStorage.getItem("selectedTrainee");
    var payload = { userId: this.currentUserID };
    this.$store
      .dispatch(FETCH_TRAINEE_DETAIL, payload)
      .then((data) => {
        this.loading = false;
        this.userData = data.userData;
        this.providerData = data.providerData;
        this.providerProgress = data.providerProgress;
        this.providerLectureProgress = data.providerLectureProgress;
        if (data.pretest != null) {
          this.traineePretestResult = data.pretest[0];
          console.log(this.traineePretestResult);
        }
        this.traineePosttestResult = data.posttest;
      })
      .catch((response) => {
        console.log(response);
        this.loading = false;
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
  created() {},
  methods: {
    filterGender(gender) {
      var response = "";
      if (gender == "f") {
        response = "Female";
      } else if (gender == "m") {
        response = "Male";
      } else if (gender == "o") {
        response = "Other";
      }
      return response;
    },
    filterMaritalStatus(data) {
      var response = "";
      if (data == "single") {
        response = "Single";
      } else if (data == "married") {
        response = "Married";
      }
      return response;
    },
    filterUserType(data) {
      if (data == "N") {
        return "Normal User";
      } else if (data == "P") {
        return "Provider";
      }
    },
  },
};
</script>
